<template>
  <div class="mb-10">
    <div>
      <div class="flex items-center justify-center px-5 py-5">
        <div class="w-full mx-auto rounded-xl bg-white shadow-sm p-10 text-gray-800 relative overflow-hidden">
            <div class="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
                <h1 class="text-2xl font-semibold whitespace-nowrap">TeleConsultant: {{emailCurrentCall}}</h1>
            </div>
              <div class="relative mt-1">
                <div class="grid grid-cols-3 gap-4">
                  <select
                    id="type"
                    name="date"
                    autocomplete="type"
                    v-model="timeserch"
                    @change="ChangeTypeDate($event)"
                    class="input-style"
                  >
                    <option class="bg-white text-gray-600" value="today">
                      {{$t('today')}}
                    </option>
                    <!-- <option class="bg-white text-gray-600" value="thisweek">
                      {{$t('this_week')}}
                    </option>
                    <option class="bg-white text-gray-600" value="lastweek">
                    {{$t('last_week')}}
                    </option> -->
                    <option class="bg-white text-gray-600" value="thismonth">
                    {{$t('this_month')}}
                    </option>
                    <option class="bg-white text-gray-600" value="lastmonth">
                    {{$t('last_month')}}
                    </option>
                    <option class="bg-white text-gray-600" value="thisyear">
                    {{$t('this_year')}}
                    </option>
                  </select>

                  <date-picker placeholder="Range date ..." format="YYYY-MM-DD" type="date" class="w-full-imp input-style" v-model="formData.startDate" range></date-picker>
                  <button
                    @click="getOrdersTeleConsultantBetweenTwoDate"
                    type="button"
                    class="border font-semiborder-indigo-500 bg-indigo-500 text-white rounded-md transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline"
                  >
                    {{$t('apply')}}
                  </button>
                </div>
              </div>
              <div class="absolute top-0 left-0 w-full h-2 flex">
                <div class="h-1 bg-green-600  flex-1"></div>
                <div class="h-1 bg-yellow-500 flex-1"></div>
                <div class="h-1 bg-green-600  flex-1"></div>
                <div class="h-1 bg-yellow-500 flex-1"></div>
                <div class="h-1 bg-green-600  flex-1"></div>
                <div class="h-1 bg-yellow-500 flex-1"></div>
              </div>
            <div class="flex -mx-4 mt-10">
               <div class="w-1/2 px-4">
                    <div class="text-sm">
                        <span class="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-green-400" >&nbsp;</span>
                        <span class="align-middle font-bold uppercase">Taux de confirmation </span>
                    </div>
                    <div class="font-medium text-lg text-green-500">
                        <span v-if="TotalOrders==0">0</span><span v-else>{{((TotalConfirmed/TotalOrders)*100).toFixed(2)}}</span>%
                    </div>
               </div>
               <div class="w-1/2 px-4 border-l border-gray-700">
                    <div class="text-sm">
                        <span class="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-green-400" >&nbsp;</span>
                        <span class="align-middle font-bold uppercase">Taux de livraison </span>
                    </div>
                    <div class="font-medium text-lg text-green-500">
                        <span v-if="TotalOrders==0">0</span><span v-else>{{((TotalDelivred/TotalOrders)*100).toFixed(2)}}</span>%
                    </div>
               </div>
            </div>  
        </div>
      </div>
      <div class="p-4 bg-white h-full">
        <!-- <chart-callcenter :chartData="tab" /> -->
        <card-line-chart
          :confirmed="confirmed"
          :cancelled="cancelled"
          :unreached="unreached"
          :skipped="skipped"
          :Labels="labels"
        />
      </div>
    </div>
    
    <div-table
      :config="configList"
      :data="content"
      :emailCurrentCall="emailCurrentCall"
      :idWharhouse="idWharhouse" :currentUser="currentUser"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
       @refresh="refresh"
      @downloadAnalytics="downloadAnalytics"
    ></div-table>
  </div>
</template>
<script>
import HeaderFilters from "@/components/Analytics/Cards/HeaderFilters.vue";
import chartCallcenter from "@/components/Analytics/Cards/chartCallcenter.vue";
import CardLineChart from "../../components/Cards/CardLineChart.vue";
import config from "./configStatus";
export default {
  components: {
    HeaderFilters,
    chartCallcenter,
    CardLineChart,
  },
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() { 
    return {
      teleConsultant: "",
      TeleConsultant: [],
      formData: {
        startDate: "",
        endDate: "",
      },
      // currentUser: {
      //   role: {},
      // },
      tab: [],
      confirmed: [],
      cancelled: [],
      unreached: [],
      dilivred:[],
      skipped: [],
      labels: [],
      configList: config.list,
      loading: false,
      content: [],
      Allcontent: [],
      limit: 10,
      timeserch: "today",
      emailCurrentCall:"",
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
      TotalDelivred:0,
      TotalConfirmed:0,
      TotalConceled:0,
      TotalOrders:0,
    };
  },
  async mounted() {
   // await this.getUsers();
    if (this.$route.params.id) {
      this.labels = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ];
      const filters = {
        id: this.$route.params.id,
        IntervaleDate: "Today",
      };

      await this.getTotalOrdersTeleConsultant(filters);
      await this.GetCurrentCallcenter();
      await this.getStatusHistories({ limit: this.limit });
      await this.getAllStatusHistories();
    }
  },
  methods: {
      async downloadAnalytics(){
        var arr = typeof this.Allcontent !== 'object' ? JSON.parse(this.Allcontent) : this.Allcontent;

        for (let i in arr) {
                arr[i].Email=arr[i].user.email;
                arr[i].dateDebut =this.$moment(arr[i].dateDebut).format('yyyy-MM-DD HH:mm');;
                arr[i].previousStatus =arr[i].previousStatus.name;
                arr[i].status=arr[i].status.name;
        }
        //console.log('array csv_____________2',arr)
        for (let i in arr) {
            delete arr[i].user;
            delete arr[i]._id;
            delete arr[i].__v;
            delete arr[i].isDeleted;
            delete arr[i].fees;
            delete arr[i].order;
            delete arr[i].comment;
            delete arr[i].date;
            }

        var str =
            `${Object.keys(arr[0])
            .map((value) => `"${value}"`)
            .join(',')}` + '\r\n';
        var csvContent = arr.reduce((st, next) => {
            st +=
            `${Object.values(next)
                .map((value) => `"${value}"`)
                .join(',')}` + '\r\n';
            return st;
        }, str);
        var element = document.createElement('a');
        element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
        element.target = '_blank';
        element.download = 'exportCallcenter_Statistics.csv';
        element.click();
        await this.getAllStatusHistories();
    },
     async ChangeTypeDate(event) {
      this.timeserch = event.target.value;
      this.content = [];
      await this.getStatusHistories({ limit: this.limit });
      await this.getAllStatusHistories();
      let filters;
      switch (event.target.value) {
        case "today":
          console.log("HIIIII JE SUIS Today");
          filters = {
            id: this.$route.params.id,
            IntervaleDate: "Today",
          };
          this.labels = [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ];
          await this.getTotalOrdersTeleConsultant(filters);

          break;
        case "thismonth":
          console.log("HIIIII JE SUIS This Month");
          let nbrjours = this.getNumberOfMonths();

          for (let i = 1; i <= nbrjours; i++) {
            this.labels.push(i);
          }

          filters = {
            id: this.$route.params.id,
            IntervaleDate: "This Month",
          };

          await this.getTotalOrdersTeleConsultant(filters);

          break;
        case "lastmonth":
          console.log("HIIIII JE SUIS This Last Month");
          let nbr_jours = this.getNumberOfDaysLastMonths();
          for (let i = 1; i <= nbr_jours; i++) {
            this.labels.push(i);
          }
          filters = {
            id: this.$route.params.id,
            IntervaleDate: "Last Month",
          };
          await this.getTotalOrdersTeleConsultant(filters);
          console.log("HII JE SUIS THIS.TAB DANS Last Month", this.tab);
          break;
        case "thisyear":
          console.log("HIIIII JE SUIS This Year");
          this.labels = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          filters = {
            id: this.$route.params.id,
            IntervaleDate: "This Year",
          };
          await this.getTotalOrdersTeleConsultant(filters);
          console.log("HII JE SUIS THIS.TAB DANS This Year", this.tab);
          break;
        default:
          this.tab = [];
      }
    },
    async GetCurrentCallcenter() {
      const filter = {
        _id: this.$route.params.id
      };
      const res= await this.$server.search("users",filter);
        if (res.content) {
        this.emailCurrentCall = res.content.results[0].fullName;
      }
    },
    async getUsers() {
      const filters = {
        type: "TeleConsultant",
      };
      const res = await this.$server.search("users", filters);
      if (res.content.results) {
        this.TeleConsultant = res.content.results;
      } else this.TeleConsultant = [];
    },
    async getTotalOrdersTeleConsultant(filters) {
      const res = await this.$server.getTotalOrdersTeleConsultant(
        "analytics",
        filters
      );

      console.log("HIII JE SUIS RES analytic this month", res);
      if (res.status == 200) {
        this.TotalConfirmed=this.TotalDelivred=this.TotalConceled=0;
        this.confirmed = res.content.data[0];
        this.cancelled = res.content.data[1];
        this.dilivred = res.content.data[2];
        
        for(let i in this.confirmed){
          this.TotalConfirmed+=this.confirmed[i];
        }
        for(let i in this.cancelled){
          this.TotalConceled+=this.cancelled[i];
        }
         for(let j in this.dilivred){
          this.TotalDelivred+=this.dilivred[j];
        }
        this.TotalOrders += this.TotalConfirmed+this.TotalConceled;
      }

        console.log("TotalConfirmed", this.TotalConfirmed);
        console.log("TotalOrders", this.TotalOrders);
    },

    async getOrdersTeleConsultantBetweenTwoDate() {
      console.log('ooooooooooooooooook')
      const filters = {
        startDate: this.formData.startDate,
        id: this.$route.params.id,
        IntervaleDate: "Two Date",
      };

      this.timeserch = "betweendate";

      if (this.formData.startDate && Array.isArray(this.formData.startDate) && this.formData.startDate.filter(function(el) { return el; }).length==2){
         this.content = [];
         await this.getStatusHistories({ limit: this.limit });
         await this.getAllStatusHistories();
        const res = await this.$server.getOrdersTeleConsultantBetweenTwoDate(
          "analytics",
          filters
        );
        console.log('res_____________',res)
        if (res && res.status == 200) {
           this.TotalConfirmed=this.TotalDelivred=this.TotalConceled=0;
          this.confirmed = res.content.data[0];
          this.cancelled = res.content.data[1];
          this.dilivred = res.content.data[2];
           this.labels= res.content.days;
           for(let i in this.confirmed){
            this.TotalConfirmed+=this.confirmed[i];
            }
             for(let j in this.dilivred){
              this.TotalDelivred+=this.dilivred[j];
           }
            for(let i in this.cancelled){
             this.TotalConceled+=this.cancelled[i];
           }
           this.TotalOrders += this.TotalConfirmed+this.TotalConceled;
      }
      
      }
    },
    getNumberOfMonths() {
      let today = new Date();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();

      let nbr_jours = new Date(year, month, 0).getDate();
      this.labels = [];
      return nbr_jours;
    },
    getNumberOfDaysLastMonths() {
      let today = new Date();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      let nbr_jours;
      if (month == 1) {
        nbr_jours = new Date(year - 1, 12, 0).getDate();
      } else {
        nbr_jours = new Date(year, month - 1, 0).getDate();
      }
      this.labels = [];
      return nbr_jours;
    },
    async getStatusHistories(filters) {

        filters.user= this.$route.params.id;
        filters.DateType= this.timeserch;
        filters.startDate=this.formData.startDate;
        filters.endDate=this.formData.endDate;
        const res = await this.$server.search("statusHistories", filters);
       if (res.content.results) {
        this.content = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
        for(let i in this.content){
          this.content[i].order_id=this.content[i].order.id;
        }
      } else this.content = [];
      console.log('this content ststus__',this.content )
    },
    async getAllStatusHistories() {
         const filters = {
             user: this.$route.params.id,
             DateType:this.timeserch,
             startDate:this.formData.startDate,
             endDate:this.formData.endDate
             
         }
        const res = await this.$server.search("statusHistories", filters);
           if (res.content.results) {
              this.Allcontent = res.content.results;
           }else this.Allcontent = [];
    },
    async refresh() {
      this.getStatusHistories({ limit: this.limit });
      await this.getAllStatusHistories();
    },

     async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: this.limit,
      };
      this.getStatusHistories(filters);
    },
  },

  watch: {
    teleConsultant: async function (newVal, oldVal) {
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
    labels: function (newVal, oldVal) {
      // watch it

      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
    
  },
};
</script>
<style scoped>
</style>