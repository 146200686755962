<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="p-4 flex-auto">
      <!-- Chart -->
      <div class="relative h-auto">
        <canvas id="line-chart" style="height: 450px"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";

export default {
  props: ["confirmed", "cancelled", "Labels"],
  data() {
    return {
      myChart: "",
    };
  },

  mounted() {
    this.drawChart(
      this.confirmed,
      this.cancelled,
      this.Labels
    );
  },
  methods: {
    drawChart(confirmed, cancelled,  labels) {
      var ctx = document.getElementById("line-chart").getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "bar", 
        data: {
          labels: labels,
          datasets: [
            {
              label: "Confirmed",
              backgroundColor: "#4c51bf",
              borderColor: "#4c51bf",
              data: confirmed,
              fill: false,
            },
            {
              label: "Cancelled",
              fill: false,
              backgroundColor: "#ffce56",
              borderColor: "#ffce56",
              data: cancelled,
            },
           
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: "Sales Charts",
            fontColor: "black",
          },
          legend: {
            labels: {
              fontColor: "black",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "gray",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Month",
                  fontColor: "gray",
                },
                gridLines: {
                  display: false,
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: "gray",
                  zeroLineColor: "gray",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "gray",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                  fontColor: "gray",
                },
                gridLines: {
                  borderDash: [3],
                  borderDashOffset: [3],
                  drawBorder: false,
                  color: "gray",
                  zeroLineColor: "gray",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      });
    },
  },
  watch: {
    Labels: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(
        this.confirmed,
        this.cancelled,
        this.Labels
      );
    },
    confirmed: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(
        this.confirmed,
        this.cancelled,
        this.Labels
      );
    },
    cancelled: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(
        this.confirmed,
        this.cancelled,
        this.Labels
      );
    },


  },
};
</script>
